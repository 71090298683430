@import url(../../base.css);

/*db-panes*/
.db-pane{
    width: 100%;
}

/*donation history brief*/
.donation-history-brief{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    
    margin-bottom: 30px;
}
.donation-history-brief h3{
    margin-top: 5px;
    margin-bottom: 5px;
    color: #445165ff;
}
.donation-history-brief-numbers{
    color: #2e333aff;
    font-size: 18px;
}

/*db pane table*/
.db-pane-table-wrapper{
    width: 100%;
    overflow-x: auto;
    margin-bottom: 20px;
    padding-bottom: 10px;
}
.db-pane-table-wrapper table{
    border-collapse: collapse;
}
.db-pane-table-wrapper table th{
    color: #445165ff;
    font-size: 14px;
    min-width: 100px;
    text-align: left;
    padding-left: 10px;
}
.db-pane-table-wrapper table td{
    color: #2e333aff;
    font-size: 14px;
    font-family: var(--arial);
    border-bottom: 1px solid #eaebefff;
    padding: 10px 0px 10px 10px;
    min-width: 100px;
}



@media screen and (min-width:912px){
    .donation-history-brief{
        gap: 50px;
    }

    .db-pane-table-wrapper table{
        width: 100%
    }
    .db-pane-table-wrapper table td,
    .preview-section-body table th{
        padding: 20px 10px;
    }
}
/*
@media screen and (min-width:1024px){
    .db-pane{
    }
}
*/