/*font: font-style font-variant font-weight font-size/line-height font-family;*/
:root{
    --poppins: 'Poppins', sans-serif;
    --inter-font: 'Inter', sans-serif;
    --raleway-font: 'Raleway', sans-serif;
    --satoshi-font: 'Satoshi', sans-serif;
    --lato-font: 'Lato', sans-serif;
    --sans-bengali-font: 'Noto Sans Bengali', sans-serif;

    --arial: 'Arial', sans-serif;
    --arial-black: 'Arial Black', sans-serif;
    --arial-light: 'Arial Light', sans-serif;
    --arial-ce: 'Arial CE', sans-serif;
    --arial-ce-mt-black: 'Arial CE MT Black', sans-serif;
    
    /*new fonts*/
    --codec-font: 'Codec Pro', sans-serif;
    --segoe-ui-font: 'Segoe UI', sans-serif;
    
    --base-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
    
    --rl-pad: 0px 15px 0px 15px;
    --6-weight: 600;
    --dark: #2e4049;
    --pale-green: rgba(146, 205, 0, 0.20);
    --gray: #f9fafb;
    
    --bs-gray: #6c757d;
    --bs-gray-dark: #343a40;
    --bs-gray-100: #f8f9fa;
    --bs-gray-200: #e9ecef;
    --bs-gray-300: #dee2e6;
    --bs-gray-400: #ced4da;
    --bs-gray-500: #adb5bd;
    --bs-gray-600: #6c757d;
    --bs-gray-700: #495057;
    --bs-gray-800: #343a40;
    --bs-gray-900: #212529;
    --base-blue: #10b7b9ff;
    --success-green: rgb(19, 177, 35);
    --base-yellow: #edbb1aff;
    --pale-yellow: #ffdc80ff;
    --base-color: #92cd00ff;
    --blog-red: #d62525ff;
    
    /*new vars*/
    --Grey---Dark: #252B5C;
    --Gray-300: #D0D5DD;
    
    /*variables for loading placeholder*/
    --shade: rgba(224, 224, 224, 0.7);
    --tint: rgb(240, 240, 240, 0.5); 
    --lp-grad-end: 110deg;
    
    --approved: #06ff00ff; 
    --pending: #F79D38;
    --rejected: #ce0000;
    --untouched: #D0D5DD;
    

}
/* -----------generic--------------- */
*{
    box-sizing: border-box; 
    font-family: var(--inter-font); 
}
html{
    width:100%; 
    overflow-x: hidden;
}
body{
    width:100%; 
    margin:0% 0px 0px 0%; 
    overflow-x:hidden; 
    position:relative;
}

.smaller-note {
    color: #2C6700;
    font-size: 12px;
    text-transform: uppercase;
    background: var(--pale-green);
    display: inline-flex;
    padding: 10px;
    font-weight: bold;
}
.service-heading{
    text-align: center; min-width: 100%;
}
.smaller-note-wrapper{
    display: flex; justify-content: center;
}
/*
.heading-text{
    font-size: 40px; font-weight: bold; color: #2e4049;
    margin-bottom: 20px; max-width: 712px; margin: auto;
}
*/


a{
    text-decoration: none;
}
a:hover{
    text-decoration: underline;
}
.pd30{
    padding: 30px;
}
.pd30x{
    padding: 0px 30px;
}
.pd30y{
    padding: 30px 0px;
}

/* -----------lazy--------------- */
img{
    font-size: 13px;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    transition: 0.5s;
    object-fit: fill;
}

/* -----------input--------------- */
input{
    border: none;
    outline: none;
}

/* -----------buttons--------------- */
button{
    border: none; cursor: pointer;
    background: none;
}
.butt a{
    text-decoration: none;
}

/* -----------green buttons--------------- */
.green-button{
    background-color: var(--base-color);
    display: flex;
    padding: 15px 0px;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 400px;
    border-radius: 25px;
    margin: auto;
    
    color: #FFF;
    text-align: center;
    font-family: var(--inter-font);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    
}
.green-button:hover{
    background-color: #72a100ff
}

.green-button-disabled{
    background-color: #ccccccff;
    color: var(--bs-gray-500);
    cursor: not-allowed;
    position: relative;
}
.green-button-disabled svg{
    position: absolute;
    left: 50px;
}
.green-button-disabled:hover{
    background-color: #ccccccff;
}


/* -----------scrollable elements on hover--------------- */
.indicate-scrollable-on-hover{
	scrollbar-width: none; /* Firefox */
	-ms-overflow-style: none; /* Internet Explorer 10+ */
}
.indicate-scrollable-on-hover::-webkit-scrollbar{
	width: 0; /* For Chrome, Safari, and Opera */
}

/* Show the scrollbar on hover */
.indicate-scrollable-on-hover:hover {
	scrollbar-width: thin; /* For Firefox */
}
.indicate-scrollable-on-hover:hover::-webkit-scrollbar {
	width: 12px; /* For Chrome, Safari, and Opera */
}


/* error note*/
.error-note{
    color: #ff5b5bff;
    font-size: 18px;
    text-align: center;
}

/* no record found */
.no-record-found{
    color: #475162ff;
    font-size: 16px;
}