:root{
    --db-helv: 'dbhelvethaicax-reg-regular', sans-serif;
    --neue-helv: 'helvetica-neue-interface-regular', sans-serif;
    --norm: 'freesans';
    --med: var(--db-helv);
    --bod: 'freesansbold';
    --rl-pad: 0px 15px 0px 15px;
    --6-weight: 600;
    --dark: #2e4049;
    --pale-green: rgba(146, 205, 0, 0.20);
    --gray: #f9fafb;

    --poppins: 'Poppins', sans-serif;
    --inter-font: 'Inter', sans-serif;
    --raleway-font: 'Raleway', sans-serif;
    --satoshi-font: 'Satoshi', sans-serif;
    --lato-font: 'Lato', sans-serif;
    --sans-bengali-font: 'Noto Sans Bengali', sans-serif;

    --arial: 'Arial', sans-serif;
    --arial-black: 'Arial Black', sans-serif;
    --arial-light: 'Arial Light', sans-serif;
    --arial-ce: 'Arial CE', sans-serif;
    --arial-ce-mt-black: 'Arial CE MT Black', sans-serif;
    
    /*new fonts*/
    --codec-font: 'Codec Pro', sans-serif;
    --segoe-ui-font: 'Segoe UI', sans-serif;
    
    --base-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
    
    --rl-pad: 0px 15px 0px 15px;
    --6-weight: 600;
    --dark: #2e4049;
    --pale-green: rgba(146, 205, 0, 0.20);
    --gray: #f9fafb;
    
    --bs-gray: #6c757d;
    --bs-gray-dark: #343a40;
    --bs-gray-100: #f8f9fa;
    --bs-gray-200: #e9ecef;
    --bs-gray-300: #dee2e6;
    --bs-gray-400: #ced4da;
    --bs-gray-500: #adb5bd;
    --bs-gray-600: #6c757d;
    --bs-gray-700: #495057;
    --bs-gray-800: #343a40;
    --bs-gray-900: #212529;
    --base-blue: #10b7b9ff;
    --success-green: rgb(19, 177, 35);
    --base-yellow: #edbb1aff;
    --pale-yellow: #ffdc80ff;
    --base-color: #92cd00ff;
    --blog-red: #d62525ff;
    
    /*new vars*/
    --Grey---Dark: #252B5C;
    --Gray-300: #D0D5DD;
    
    /*variables for loading placeholder*/
    --shade: rgba(224, 224, 224, 0.7);
    --tint: rgb(240, 240, 240, 0.5); 
    --lp-grad-end: 110deg;
    
    --approved: #06ff00ff; 
    --pending: #F79D38;
    --rejected: #ce0000;
    --untouched: #D0D5DD;
}
*{
    box-sizing: border-box; 
    font-family: var(--lato-font);
}
html{
    width:100%; 
    overflow-x: hidden;
    background-color: #fff;
}
body{
    width:100%; 
    margin:0% 0px 0px 0%; 
    overflow-x:hidden; 
    position:relative;
}

/* -----------lazy--------------- */
[lazy]{
    background-image: linear-gradient(90deg, #96988aff, #98958cff, #9f9f87ff, #7baaa0ff, #8ea37fff);
    background-position: center;
    background-size:cover; background-repeat: no-repeat;
}

/* -----------lazy--------------- */
img{
    font-size: 13px;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    transition: 0.5s;
    object-fit: fill;
}

/* -----------buttons--------------- */
button{
    border: none; 
}
.butt a{
    text-decoration: none;
}
.butt{
    overflow: hidden; border-radius: 40px;
}
.inner-butt{
    display: flex; justify-content: center; align-items: center; 
    color: white; font-size: 16px;
    font-weight: var(--6-weight); line-height: 24px; width: 108%; 
    height: 105%; margin: -1px 0px 0px -5px; padding: 15px;
}




.donate-button{
    background-color: var(--dark); min-width: 190px;
}
.donate-button .inner-butt>span{
    font-size: 18px;
}
.learn-more-button{
    background: none; border: 1px solid var(--dark);
}
.learn-more-text{
   color: var(--dark)
}



@media screen and (min-width:1280px){
    .heading-text{
        font-size: 50px;
    }
}