@import url('auth_base.css');


/* -----------custom checkbox--------------- */
.check-wrapper{
    display: flex; align-items: center; margin-bottom: 10px;
}
.custom-checkbox{
    display: none; /* Hide the default checkbox */
}
.custom-checkbox + label{
    position: relative;
    padding-left: 25px; /* Adjust the padding based on your design */
    cursor: pointer; 
    padding-top: 4px;
    
    color: var(--Gray-500, #667085);
    font-family: var(--inter-font);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal; 
}
.custom-checkbox + label:before{
    content: '';
    position: absolute;
    top: 0px;
    left: 0;
    width: 18px; /* Adjust the width and height based on your design */
    height: 18px;
    border: 2px solid #e6ecf5; /* Grey border color */
    border-radius: 5px;
    background-color: #fff; /* White background color */
    display: flex; text-align: center; justify-content: center;
}
.custom-checkbox:checked + label:before{
    content: '✔'; /* Unicode check mark symbol */
    font-size: 14px; /* Adjust the font size based on your design */
    line-height: 20px; /* Center the check mark vertically */
    text-align: center;
    color: var(--base-color); /* Green check mark color */
}


/*remember-me-forgot-password-wrapper*/
.remember-me-forgot-password-wrapper{
	display: flex; 
  justify-content: space-between;
	font-size: 13px; 
  margin-bottom: 30px; 
  align-items: center;
  padding: 0px 10px;
}
.signin-form .custom-checkbox + label:before{
	margin-top: 2px;
}
.forgot-password{
	font-family: var(--inter-font);
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: normal; 
}
.forgot-password a{
	color: var(--base-color);
}