@import url(db_pane.css);

.donation-history-pane{
  padding: 0px;
}
.donation-history-pane-table thead{
  background-color: #f7f7f7ff;
}
.donation-history-pane-table tr:first-child th{
  padding: 20px;
}
.donation-history-pane-table .status-state{
  border: 1px solid #eaebefff;
  padding: 10px;
  border-radius: 20px;
  max-width: 130px;
  
  display: flex;
  align-items: center;
  gap: 10px;
}
.donation-history-pane-table .status-state span:first-child{
  width: 12px;
  height: 12px;
  border-radius: 50%;
}
.donation-history-pane-table .completed{
  color: var(--approved);
}
.donation-history-pane-table .completed span:first-child{
  background-color: var(--approved);
}
.donation-history-pane-table .pending{
  color: var(--pending);
}
.donation-history-pane-table .pending span:first-child{
  background-color: var(--pending);
}
.donation-history-pane-table .failed{
  color: #ff1e00ff;
}
.donation-history-pane-table .failed span:first-child{
  background-color: #ff1e00ff;
}