@import url("../landing_base.css");

/* -----------success stories--------------- */
.success-stories{
    padding: 20px; 
    
    display: flex; 
    flex-wrap: wrap; 
    align-items: flex-start;
    justify-content: center; 
    gap: 20px; 
    
    margin-bottom: 70px
}
.success-stories-heading+p{
    color: var(--dark); 
    font-size: 20px; 
    font-family: var(--med);
}

.success-card{
    min-height: 250px; 
    width: 100%; 
    max-width: 400px; 
    margin: auto;
    position: relative;
    border-radius: 5px;
}
.success-card>img{
    object-fit: cover;
}

.success-heading-card-wrapper{
    background: var(--gray); 
    padding: 15px; 
    max-width: 500px;
}
.success-heading-card-wrapper .success-card{
    min-height: 500px; 
    background: url("https://www.gofundme.com/c/wp-content/uploads/2023/11/YuandMe_Desktop.jpg") center/cover no-repeat;
}
.success-heading-card-wrapper .success-card img{
    height: 120% !important;
    border: 1px solid;
    display: none;
}


.success-stories-card-wrapper{
    display: flex; 
    gap: 20px; 
    flex-wrap: wrap; 
    flex: 400px;
}
.success-stories-card-wrapper>div{
    flex: 300px;
}


/*story*/
.story{
    position: absolute;
    top: 0;
    left: 0;
    
    height: 100%;
    width: 100%;
    
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 10px;
    
    padding: 10px;
}
.story-head{
    font-weight: bold; font-size:20px; color: white
}
.story-sub{
    font-size:20px; 
    font-family: var(--med); 
    color:#2e4049;
    background: rgba(255,255,255,0.70); 
    padding: 5px; 
    border-radius: 4px;
}


@media screen and (min-width:912px){
    /* -----------success stories--------------- */
    .success-stories{
        flex-wrap: nowrap;
    }
    .success-stories-card-wrapper .success-card{
        margin: unset; 
    }
}
@media screen and (min-width:1280px){
    /* ----------- section--------------- */
    .success-stories-card-wrapper .success-card{
        min-height: 400px; 
    }
}