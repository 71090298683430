@import url("../landing_base.css");

/* -----------aside--------------- */
aside{
    display: flex; padding: var(--rl-pad); flex-wrap: wrap;
    justify-content: space-between; margin-bottom: 80px;
}
.aside-note{
    margin-bottom: 20px
}
.aside-note>span:nth-of-type(1){
    font-size: 30px; font-weight: bold; color: #2e4049;
    margin-bottom: 20px;
}
.aside-note>span:nth-of-type(2){
    font-size: 20px; color: #475467; font-family: var(--med)
}
aside>div:nth-of-type(2){
    display: flex; flex-wrap: wrap; 
    justify-content:  center; width: 100%; max-width: 540px;
}
.aside-email-input-container label{
    display: none; flex: 100%; font-size: 13px;
}
.aside-email-input-container input{
    width: 95%; outline: none; border: none; color: #667035;
}
.aside-email-input-container{
    width:100%; max-width: 300px; height: 50px; border: 1px solid #d0d5dd;
    padding-left: 8px; margin-bottom: 10px; border-radius: 5px;
    display: flex; align-items: center;  flex-wrap: wrap;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
}
.aside-privacy-note{
    color: #475467; font-family: var(--med);
}
aside .subscribe-butt{
    position: relative; top: unset; right: unset;;
}


@media screen and (min-width:912px){
    /* -----------aside--------------- */
    aside{
        flex-wrap: nowrap;
    }
    aside>div:nth-of-type(2){
        padding-top: 10px;
    }
}
@media screen and (min-width:1280px){
    aside{
        padding-left: 50px;
        padding-right: 50px;
    }
}