.testimonial-of-fulfillment-section{
  background-color: #364cdfff;
  padding: 100px 20px;
}
/*tfs sub*/
.tfs-sub{
  background-color: #FFF;
  border-radius: 20px;
  padding: 50px 0px;
  
}
/*tfs head*/
.tfs-head{
  display: flex;
  flex-direction: column;
  align-items: center;
  
  padding: 0px 10px;
}

.tfs-tag{
  background-color: black;
  width: 100%;
  max-width: 160px;
  height: 30px;
  border-radius: 20px;
  padding: 5px 10px;
  
  display: flex;
  gap: 10px;
  align-items: center;
}
.tfs-tag>div:nth-child(1){
  height: 100%;
  width: 20px;
  border-radius: 50%;
  background-color: #344cdcff;
  
  display: flex;
  align-items: center;
  justify-content: center;
}
.tfs-tag>div:nth-child(1) img,
.tfs-tag>div:nth-child(1) svg{
  width: 50%;
  height: 50%;
}
.tfs-tag>div:nth-child(2){
  color: #fff;
}

.tfs-sub h2{
  font-size: 40px;
  color: #000;
}

/*tfs-entries*/
.tfs-entries{
  flex: 1;
  width: 100%;
  height: 280px;
  
  position: relative;
  overflow: hidden;
}
/*tfs-entry*/
.tfs-entry{
  background-color: #f3f5f6ff;
  width: 400px;
  
  padding: 30px 20px;
  border-radius: 20px;
  border: 1px solid #eaebefff;
  
  position: absolute;
  top: 0;
  
  transition-property: transform; 
  transition-timing-function: linear;
  right: -400px;
}
.tfs-blue-double-quote-wrapper img,
.tfs-blue-double-quote-wrapper svg{
  width: 35px;
  height: 30px;
}
.tfs-entry p{
  color: #2e333aff;
  font-size: 20px;
}
.tfs-entry p span{
  font-weight: 600;
}
/* tfs avatar and name*/
.tfs-avatar-et-name{
  display: flex;
  align-items: center;
  gap: 15px;
}
.tfs-avatar-wrapper img{
  width: 30px;
  height: 30px;
}
.tfs-name-wrapper{
  color: #414141ff;
  font-size: 16px;
}


@media screen and (min-width: 1280px){
  .testimonial-of-fulfillment-section{
    padding: 100px 50px;
  }
}