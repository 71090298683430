@import url("../landing_base.css");

/* -----------volunteer--------------- */
.volunteer-section{
    background: var(--gray); padding: 20px; padding-top: 40px;
    margin-bottom: 40px;
}
.volunteer-card-wrapper{
    position: relative; height: 200px;
}
.volunteer-card{
    width: 300px; 
    background: white;  
    border-radius: 4px; height: 150px; 
    display: flex; 
    align-items: center; 
    padding: 15px; 
    right: -320px; 
    position: absolute;
    transition-property: transform; 
    transition-timing-function: linear; 
}
.volunteer-card svg{
    width: 120px;
}
.volunteer-card>span:nth-of-type(2){
    padding-left: 20px
}
.volunteer-name{
    font-size: 20px; font-weight: bold; color: #2e4049;
    margin-bottom: 20px;
}
.volunteer-county{
    font-size: 20px; color: #475467; font-family: var(--med);
}