@import url('../base.css');

.homepage-blog-snippet{
  padding: 0px 30px;
  margin-bottom: 50px;
}

.blog-section h2{
  color: #000;
  font-size: 30px;
  margin-bottom: 5px;
}
.doubled-underline div{
  width: 100%;
  height: 2px;
  content: '';
  left: 0px;
  border-radius: 5px;
}
.doubled-underline div:nth-child(1){
  background-color: #c5c57aff;
  margin-bottom: 4px;
}
.doubled-underline div:nth-child(2){
  background-color: #c57a7aff;
}


/* blog listing*/
.blog-story-listing{
  margin-top: 30px;
  
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 400px));
  gap: 50px;
}
.blog-story-listing-entry{
  display: flex;
  flex-direction: column;
  gap: 10px;
  
  width: 100%;
  max-width: 400px;
}
.blog-story-tag{
  position: relative;
  margin-bottom: 10px;
}
.blog-story-tag::after{
    width: 100%;
    height: 2px;
    content: '';
    left: 0px;
    bottom: 0px;
    border-radius: 5px;
    background-color: #c5c57aff;
    position: absolute;
}
.blog-story-tag>div{
  background-color: var(--blog-red);
  display: inline-block;
  padding: 3px 15px;
  color: #fff;
}

/*blog listing image*/
.blog-story-image-wrapper{
  border-radius: 4px;
  margin-bottom: 10px;
  height: 260px
}
.blog-story-image-wrapper img{
  object-fit: cover;
}

/*datetime published detail*/
.published-detail span:nth-child(1){
  color: #6e8264ff;
}
.published-detail span:nth-child(2){
  color: #666666ff;
  color: var(--bs-gray);
}

/*story short*/
.blog-story-short{
  margin: 0px;
  color: #2e333aff;
  font-size: 16px;
  text-align: justify;
}

/* read more action */
.read-more-action-wrapper{
  display: flex;
  justify-content: flex-end;
  margin-top: -5px;
}
.read-more-action-wrapper button{
  color: var(--blog-red);
  font-size: 15px;
}


@media screen and (min-width:1280px){
  .blog-story-listing{
    grid-template-columns: repeat(auto-fit, minmax(350px, 400px));
  }
}