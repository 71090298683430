@import url("../landing_base.css");

/* -----------assistance section--------------- */
.assistance{
    padding: 20px;
}

.assistance-heading-wrapper{
    margin-bottom: 20px;          
}
.assistance-heading+div{
    max-width: 1000px; 
    display:flex; 
    flex-direction: column;
    align-items: center;
}
.assistance-note{
    color: #101828; 
    font-size: 20px; 
    font-family: var(--med);
    padding-bottom: 20px;
    text-align: justify;
}
.assistance-img{
    height: 700px;
}


@media screen and (min-width:912px){
    /* -----------assistancd section--------------- */
    .assistance-heading-wrapper{
        display: flex;
        align-items: flex-start;
        gap: 20px;
    }
    .assistance-heading-wrapper>div{
        flex: 50%;
    }
    .assistance-note{
        padding-top: 20px;
    }
}

@media screen and (min-width:1280px){
    .assistance-heading-wrapper{
        padding-left: 50px;
        padding-right: 50px;
    }
}