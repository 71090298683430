@import url('auth_base.css');


/*verify email section*/
.verify-email-address-form header{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.verify-email-address-form .move-to-previous-slide{
  position: absolute;
  left: 5px;
  top: 15px;
}
.verify-email-note{
  color: var(--bs-gray-800)
}


@media screen and (min-width:712px){
  .fullname-wrapper,
  .passwords-wrapper{
    display: flex;
    gap: 20px;
  }
  .fullname-wrapper>div,
  .passwords-wrapper>div{
    flex: 1;
  }
}
