@import url(dashboard.css);


/*-----------sidebar----------*/
.sidebar{
    background-color: #f5f7f9ff;
    height: 100vh;
    width: 100vw;
    max-width: 360px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    left: -100vw;
    transition: 1s;
    
        
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.sidebar-head-et-content{
    display: flex;
    flex-direction: column;
    gap: 30px;
}

/*sidebar head*/
.sidebar-head{
    display: flex;
    justify-content: space-between;
    padding: 30px 20px 0px 20px;
    position: relative;
}
.sidebar-head::after{
    content: "";
    position: absolute;
    bottom: -20px;
    border-top: 1px solid #dcdee4ff;
    width: 90%;
}
.sidebar-head-flex1{
    display: flex;
    gap: 15px;
}
.black-box{
    width: 50px;
    height: 50px;
    border-radius: 10px;
    background-color: #000;
    
    display: flex;
    justify-content: center;
    align-items: center;
}
.black-box img,
svg{
    width: 80%;
}
.sidebar-head-names{
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.sidebar-head-names>span:nth-of-type(1){
    color: #445165ff;
    font-weight: 600;
    font-size: 20px;
}
.sidebar-head-names>span:nth-of-type(2){
  color: #657180ff;  
}
/*menu close btn style*/
.menu-close-btn{
    width: 35px !important;
}

/*sidebar content*/
.sidebar-content{
    height: 70vh;
    overflow-y: auto; 
    padding: 0px 20px;
}
.sidebar-content ul{
    list-style-type: none;
    padding: 0px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.sidebar-content li{
    color: #7f7f7fff;
    border-radius: 10px;
    padding: 15px 10px;
    cursor: pointer;
    transition: 0.5s;
    
    display: flex;
    gap: 10px;
    align-items: flex-end;
    justify-content: flex-start !important;
    font-size: 15px;
}
.sidebar-content li svg{
    width: 30px;
}
.sidebar-content li:hover{
    background-color: #ebebebff;
}
.sidebar-content li[class=active]{
    color: #2c3440ff;
    font-size: 18px;
    background-color: white;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
}
.sidebar-content li[class=active]:hover{
    background-color: #fff;
}
.sidebar-content li img{
    width: 30px;    
}

/*sidebar-footer*/
.sidebar-footer{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f5f7f9ff;
    padding: 20px 0px 
}
.logout-trigger{
    color: #7f7f7fff;
    font-size: 16px;
}

@media screen and (min-width:360px){
    .sidebar{
        left: -360px;
    }
}

@media screen and (min-width:912px){
    .sidebar{
        left: 0px;
        width: 300px;
    }
    /*mainbar*/
    .menu-icon-wrapper{
        display: none;
    }
}
