.loading-placeholder-wrapper{
    margin: auto;
    margin-top: 50px;
    margin-bottom: 50px;
    width: 100%;
    height: 250px;
    
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 30px;
}
.loading-placeholder:first-child{
    height: 60%;
}
.loading-placeholder:first-child + div{
    height: 25%;
    width: 70%;
}
.loading-placeholder:last-child{
    height: 15%;
    width: 50%
}
.loading-placeholder{
    width: 100%;
    border-radius: 20px;
    background-color: #f7f7f7ff;
    
    overflow: hidden;
    position: relative;
}
.loading-placeholder::before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.05), transparent);
    animation: loadingAnimation 1.5s ease infinite;
    backdrop-filter: blur(5px);
}
@keyframes loadingAnimation {
    0% {
        left: -100%;
    }
    100% {
        left: 100%;
    }
}