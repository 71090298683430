@import url('../base.css');


.auth-container{
    background-color: #f5f7f9ff;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

/* auth form */
.auth-form{
  position: relative;
  overflow: hidden;
}
.auth-form>div{
  position: absolute;
  left: 100%;
  top: 0;
  transition: 1s;
}
.auth-form>div:nth-of-type(1){
  position: relative;
  left: 0%;
}

/*introductory*/
.introductory{
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 70px;
}
.td{
    display: flex;
    gap: 5px;
}
.td-image{
    width: 40px;
}
.td-name{
    font-family: var(--segoe-ui-font);
    font-size: 16px;
    color: #293800ff;
}

.introductory-message{
    color: #2e4049ff
}
.introductory-message h1{
    margin: 5px;
    font-family: var(--codec-font);
}
.introductory-message span{
    padding-left: 10px;
}


/*form container*/
.form-container{
    background-color: #FFF;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    flex: 1;
    padding: 30px;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
}
.form-container h2{
    font-size: 20px;
    color: #2e4049ff;
    margin-bottom: 30px;
}

label{
    color: #989898ff;
    padding-left: 15px;
}
.input-wrapper input,
.input-wrapper textarea{
    border: 1px solid #ccccccff;
    width: 100%;
    height: 60px;
    border-radius: 20px;
    padding: 10px 20px;
    color: var(--bs-gray-700);
    font-size: 16px;
}
.password-input-wrapper{
    position: relative;
    border: 1px solid #ccccccff;
    border-radius: 20px;
    padding-right: 5px;
    
    display: flex;
    align-items: center;
    gap: 5px;
}
.password-input-wrapper input{
    border: none !important;
    padding-right: 0px !important;;
}
.password-input-wrapper img,
.password-input-wrapper svg{
    width: 20px;
    right: 5px;
    cursor: pointer;
}
.input-error{
	font-size: 14px;
	padding-left: 30px;
	color: var(--rejected);
}
.form-error{
    text-align: center;
}
.form-success{
    font-size: 14px;
    color: var(--base-color);
    text-align: center;
}
.form-container input:focus{
    border-color: var(--base-color) 
}
.input-wrapper{
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
}
.form-container .green-button{
    margin-top: 40px;
}
.form-container .green-button svg{
    width: 100px
}

/*form doodle*/
.doodle{
    display: none;
}

/*auth alternative option*/
.auth-alt-option{
    color: #2e4049ff;
    font-size: 15px;
    display: flex;
    gap: 7px;
    justify-content: center;
}
.auth-alt-option a{
    color: #2e4049ff;
    text-decoration: none;
}
.auth-alt-option>span:nth-of-type(2){
    font-size: 16px;
    border-bottom: 1px solid var(--bs-gray-600);
}


@media screen and (min-width:712px){
    .form{
        max-width: 712px;
    }
}
@media screen and (min-width:912px){
    .form-doodle-container{
      display: flex;
    }
    .form{
      flex-basis: 800px;
    }
    .doodle{
        display: block;
    }
}
@media screen and (min-width:1024px){
    .auth-container{
      flex-direction: row;
    }
    .auth-container>div{
      height: 100vh;
      overflow-y: auto;
    }
    .form-container{
      padding-top: 80px;
    }
    .form-doodle-container{
      padding: 0px 50px;
      order: 1;
    }
    .auth-alt-option{
      justify-content: flex-end;
      order: 0;
        width: 100%
    }
}