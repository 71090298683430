@import url("db_pane.css");


/*preview*/
.preview-pane{
    display: flex;
    flex-direction: column;
    gap: 50px;
    padding: 30px 20px;
}
.preview-section-tag{
    display: flex;
    align-items: flex-end;
    gap: 10px;
    
    margin-bottom: 10px;
    padding-left: 10px;
}
.preview-section-tag img,svg{
    width: 20px;
}
.preview-section-tag h4{
    margin: 0px;
    color: #59663eff;
}
.preview-section-body{
    border: var(--db-border-style);
    border-radius: 20px;
    padding: 20px 10px;
}
.preview-section-body h2{
    color: #2e2f45ff;
}

/*see more*/
.preview-section-see-more{
    display: flex;
    justify-content: flex-end;
}
.preview-section-see-more button{
    background-color: #f4f4f4ff;
    padding: 12px 15px;
    border-radius: 10px;
    color: #2e2f45ff;
}
.preview-section-see-more button:hover{
    background-color: #ebebebff;
}

@media screen and (min-width:1024px){
    .preview-section-body{
        padding: 30px;
    }
    .preview-pane{
        padding: 30px;
    }
}
