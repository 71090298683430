@import url("db_pane.css");


.fundraiser-form{
    width: 100%;
    max-width: 550px; 
    margin: auto;
    padding: 15px; 
    
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.fundraiser-main,
.fundraiser-aside{
    border-radius: 20px; 
}

.fundraiser-form h2{
    text-align: center; 
    color: #1ba200ff; 
    font-size: 25px; 
    font-weight: bold; 
    margin-bottom: 50px;    
}

/*text area*/
textarea{
    resize: none; 
    width: 100%;
    border-radius: 20px; 
    outline: none; 
    border: none;         
    display: flex; 
    padding: 20px; 
    overflow-y: hidden;
}

.notice,
.fundraiser-submit-button-wrapper{
    border-top: 1px solid rgba(32,32,32,0.1); 
    color: #202020ff;
    padding-top: 15px; 
    padding-bottom: 15px;
}

/*tip*/
.tip>p{
    font-size: 17px;
}
.tip-summary{
    display: flex; justify-content: space-between; font-size: 20px;
    flex-wrap: wrap;
}

/*notice*/
.notice h4{
    color: #da2626ff;
    color: var(--pending);
}
.notice p{
    font-size: 17px
}
.notice span{
    display: inline-block; 
    padding-top: 10px; 
    color: #1ba200ff; 
    font-size: 18px;
}

/*request help wrapper*/
.fundraiser-submit-button-wrapper{
    padding-top: 30px; 
    display: flex; 
    justify-content: center;
}
.fundraiser-submit-button-wrapper button{
    border: none; 
    width: 80%; 
    color: white; 
    background: black;
    height: 60px; 
    border-radius: 50px; 
    font-size: 20px;
    font-weight: bold; 
    cursor: pointer;
}
.fundraiser-submit-button-wrapper button svg{
    width: 70px;
}


/*-----aside-----*/
.fundraiser-aside{
    margin-bottom: 20px
}
.perks{
    display: flex; 
    flex-wrap: wrap; 
    justify-content: space-between;
}
.perks>div{
    display: flex; margin-bottom: 30px;
}
.perks>div>div:nth-of-type(1){
    margin-right: 20px;
}
.perk-head{
    font-size: 20px; 
    font-weight: bold;
}