@import url("fundraiser_base.css");


/*fundraiser base*/
.fundraiser-info{
    border-top: 1px solid rgba(32,32,32,0.1); 
    color: #202020ff;
    padding-top: 15px; 
    padding-bottom: 15px;
}
