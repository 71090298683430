@import url("../landing_base.css");


/* -----------service section--------------- */
.services{
    margin-bottom: 40px;
}
.service-image-wrapper{
   height: 200px;
}
.service-image-inner-wrapper{
    border: 5px #92CD00 dotted;
    transform: rotate(-20deg);
    transform-origin: 0 0; position: relative;
    width: 900px; height: 200px; left: -600px;
    top: 290px; border-radius: 100px;
}
.service-image{
    height: 100%; border-radius: inherit; width: 100%;
    background-image: url("https://res.cloudinary.com/derzcse6f/image/upload/v1727517451/volunteer_qbanx3.jpg");
    background-size: 40%; background-repeat: no-repeat;  
    background-position: right;
}
.service-heading{
    text-align: center; min-width: 100%;
}
.smaller-note-wrapper{
    display: flex; justify-content: center;
}
.smaller-note{
 color: #2C6700; font-size: 12px;
 text-transform: uppercase; background: var(--pale-green);  display: inline-flex; 
 padding: 10px; font-weight: bold;
}
.service-text-wrapper{
    position: relative;
    margin-top: 60px;
    padding: 0px 20px;
}
.service-card-wrapper{
    display: flex; 
    flex-wrap: wrap; 
    justify-content: space-around; 
    gap: 30px;
    padding: var(--lr-pad);
}
.service-card{
    border: 1px solid #eaecf0; width: 95%; max-width: 400px; 
    padding: 15px; border-radius: 4px;
}
.service-card-top{
    font-size: 20px; display: flex; align-items: center; gap: 10px;
    font-weight: bold; color: var(--dark); padding-bottom: 20px;
}
.service-card-top svg{
    width: 120px;    
}
.service-card-action{
    font-size: 20px; display: flex;
    border-top: 1px solid #eaecf0; 
    padding-top: 20px; 
    justify-content: center;
    gap: 10px;
    align-items: center;
}
.service-card-action  a{
    color: #e85353; text-decoration: none;
}
.service-card-action>span:nth-of-type(2){
    padding-top: 4px;
}


@media screen and (min-width:712px){
    /* -----------services section--------------- */
    .services{
        margin-top: -100px;
    }
}
@media screen and (min-width:1280px){
    /* -----------service section--------------- */
    .service-text-wrapper{
        margin-top: -10px
    }
}