@import url(../base.css);

:root{
    --db-border-style: 1px solid rgba(204,204,204,0.4)
}


.gray-db-backdrop{
    position: relative;
    width: 100%;
    max-width: 2000px;
    background-color: #f5f7f9ff;
}

/*menu open and close button style*/
.menu-icon-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    
    min-width: 70px;
    background-color: #FFF;
    border-radius: 15px;
    padding: 10px;
    border: var(--db-border-style);
    cursor: pointer;
}
.menu-icon-wrapper button{
    width: 40px;
}

/* right suspended quick access */
.right-suspended-quick-access{
    position: fixed;
    z-index: 0;
    bottom: 20px;
    right: 20px;
    padding: 0px 15px;
    
    background-color: #f5f5e8ff;
    border-radius: 7px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1),
        -2px -2px 5px rgba(0, 0, 0, 0.1);
    
    display: flex;
    align-items: center;
    gap: 10px;
}
.right-suspended-quick-access img{
    width: 25px;
}
.right-suspended-quick-access ul{
    list-style-type: none;
    padding: 0px;
    
    display: flex;
    flex-direction: column;
    gap: 3px;
}
.right-suspended-quick-access li,
.right-suspended-quick-access a{
    font-size: 14px;
    color: #293800ff;
}


@media screen and (min-width:2000px){
    .gray-db-backdrop{
        border-radius: 50px;
    }
}