

.show-wrapper{
    max-width: 1300px; 
    margin: auto; 
    overflow: hidden; 
    position: relative;
    margin-bottom: 200px; 
    height: 500px; 
    top: 150px; 
}
.show{
    position: relative; height: 100%;
}

.show>div img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.show1{
    width: 500px; height: 100%;  
}
.show2,.show4{
    position: absolute; width: 400px; height: 50%;
    left: 500px; top: 0;
}
.show3,.show5{
    position: absolute; width: 450px; height: 50%;
    left: 900px; top: 0;
}
.show4{
    top: 50%;
}
.show5{
    top: 50%;
}


.about{
    max-width: 700px; margin: auto; font-size: 18px; padding: var(--rl-pad);
    margin-bottom: 50px;
}
.about-heading{
    font-size: 50px; font-weight: bold;
}
.about p{
    color: #202020ff
}
