@import url(db_pane.css);


.campaign-pane .pane-content{
  display: flex;
  flex-direction: column;
  gap: 50px;
  
}
.pane-titlebar{
  padding: 15px 10px 10px 15px;
  border-bottom: 1px solid #eaebefff;
}
.pane-titlebar img,svg{
  width: 30px;
}
.pane-titlebar div{
  display: flex;
  align-items: flex-end;
  gap: 10px;
}
.pane-titlebar div h4{
  margin: 0px;
  font-size: 14px;
  color: #414141ff;
}

.pane-content{
  padding: 20px;
}

/*campaign entry*/
.campaign-pane-entry{
  display: flex; 
  flex-direction: column;
  gap: 20px;
  
  border: 1px solid #eaebefff;
  border-radius: 20px;
  padding: 10px;
}
.pane-header h2{
  margin: 0px;
  color: #2e2f45ff;
}
.campaign-pane-img-wrapper{
  border-radius: 4px;
}
.campaign-pane-numbers,
.campaign-pane-dates{
  display: flex;
  gap: 20px;
}
.campaign-pane-numbers-entry,
.campaign-pane-dates-entry{
  display: flex;
  flex-direction: column;
  gap: 10px; 
}
.campaign-pane-numbers-entry>div:nth-of-type(1),
.campaign-pane-dates-entry>div:nth-of-type(1){
  color: #445165ff;
  font-size: 14px;
}
.campaign-pane-entry p{
  color: #2e333aff;
  font-size: 16px;
}
.campaign-pane-reg-action-btns{
  display: flex;
  justify-content: flex-end;
}
.switch{
  background-color: #f7f7f7ff;
  width: 100%;
  max-width: 300px;
  height: 60px; 
  border-radius: 20px;
  
  position: relative;
}
.switch-underlay{
  width: 100%;
  height: 100%;
  
  display: flex;
}
.switch-underlay>div{
  width: 50%;
  height: 100%;
  
  display: flex;
  justify-content: center;
  align-items: center;
}
.switch-underlay>div img{
  width: 20px;
}
.switch-overlay{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  
  padding: 5px 7px;
}
.switch-overlay .toggle{
  width: 50%;
  height: 100%;
  background-color: #FFF;
  border-radius: 20px;
  transition: 0.5;
  
  display: flex;
  align-items: center;
  justify-content: center;
  
  color: #5a5a5aff;
  font-size: 16px;
}
.switch-overlay .active{
  color: #06ff00ff;
}
.switch-overlay .disabled{
  color: var(--bs-gray-500)
}

@media screen and (min-width:712px){
  .campaign-pane-entry{
    padding: 30px;
  }
}