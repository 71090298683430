.expired-session-overlay{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 5;
  background-color: rgba(144,144,144,0.7);
  padding: 20px;
  
  display: flex;
  justify-content: center;
  align-items: center;
}
.expired-session-display{
  background-color: #fff;
  padding: 30px;
  border-radius: 15px;
  width: 100%;
  max-width: 600px;
  min-height: 200px;
  
  display: inherit;
  align-items: inherit;
  justify-content: inherit;
  flex-direction: column;
  gap: 10px;
}
.expired-session-display p{
  color: #ff8400ff;
  font-size: 20px;
}
.expired-session-display p+div{
  font-size: 18px;
  font-weight: 600;
}
.expired-session-display p+div a{
  color: #2f2e5cff;
}