@import url(dashboard.css);

/*-----------mainbar----------*/
.mainbar{
    padding: 0px 10px 0px 10px;
    margin-bottom: 50px;
}


/*header*/
.mainbar header{
    display: flex;
    gap: 15px;
    
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    
    width: 100%;
    padding: 20px 10px 0px 10px;
}
.mainbar header>div{
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
}
.mainbar-menu-btn svg{
    width: 30px;
}
.top-complimentary{
    flex: 1;
    
    background-color: #fff;
    border-radius: 15px;
    padding: 10px;
    border: 1px solid rgba(204,204,204,0.4);
}
.top-complimentary .greeting{
    color: #445165ff;
    font-size: 20px;
    font-weight:600;
    font-family: var(--sans-bengali-font);
}
.top-complimentary .date{
    color: #657180ff;
    font-size: 15px;
}

/*dashboard main*/
.mainbar main{
    background-color: #fff;
    border-radius: 20px;
    border: var(--db-border-style);
}



@media screen and (min-width:912px){
    .mainbar{
        margin-left: 300px;
        padding: 0px 30px;
    }
    .mainbar header{
        left: 320px;
    }
    .top-complimentary{
        padding: 10px 20px;
    }
    /*mainbar*/
    .menu-icon-wrapper{
        display: none;
    }
}
