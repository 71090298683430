@import url(db_pane.css);

.impact-update-pane{
    padding: 0px;
}
.impact-update-pane .pane-content{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
