@import url("blog.css");


.blog-story-detail h1{
  color: #000;
  padding: 0px 30px;
}
.blog-story-detail h2{
  color: #414141ff;
  position: relative;
}
.blog-story-detail h2::after{
    width: 120px;
    height: 3px;
    content: '';
    bottom: -1px;
    left: 0px;
    border-radius: 5px;
    background-color: var(--blog-red);
    position: absolute;
}
.blog-story-detail .doubled-underline{
  padding: 0px 30px;
}

.blog-story-detail{
  margin-top: 170px;
}
.blog-story-detail-main{
  margin-top: 50px;
  padding: 0px 30px;
  margin-bottom: 50px;
}

/*blog cover*/
.blog-story-detail-image-wrapper{
  border-radius: 4px; 
  max-width: 95%;
  margin: auto;
  height: 500px;
}
.blog-story-detail-image-wrapper img{
  object-fit: cover;
}

  
/*tags*/
.blog-story-detail-tag-list{
  display: flex;
  gap: 20px;
  margin-top: 15px;
}
.blog-story-detail-tag-list>div{
  background-color: var(--blog-red);
  color: #fff;
  padding: 5px 15px;
  border-radius: 2px;
}

/*blog story published detail*/
.blog-story-detail-main .time-posted{
  margin-top: 20px;
}

.blog-story-full-desc{
  color: var(--bs-gray-800);
  text-align: justify;
}

.published-author>span:nth-child(2){
  color: var(--blog-red)
}


/*----------comments----------*/
.blog-story-detail-comment-section h2{
   padding: 0px 30px;
}
.blog-story-detail-comment-section h2::after{
    left: 30px;
}
.blog-story-detail-comment-wrapper{
  padding: 0px 30px;
  margin-bottom: 30px;
  
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 300px));
  gap: 30px;
}
.blog-story-detail-comment{
  border: 1px solid #eaebefff;
  border-radius: 10px;
  padding: 10px;
}
.blog-story-detail-comment p{
  color: var(--bs-gray-800);
  font-size: 15px;
}
.blog-story-detail-comment .comment-published-date{
  color: #674e4eff;
  font-size: 14px;
  
  display: flex;
  justify-content: space-between;
}

/* comment form wrapper */
.comment-form-wrapper{
  background-color: #f9f6f6ff;
  padding: 30px;
  display: flex;
  justify-content: center;
}
.comment-form{
  background-color: #fff;
  padding: 30px 10px;
  width: 100%;
  max-width: 500px;
  border-radius: 15px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.05),
    -2px -2px 5px rgba(0, 0, 0, 0.05);
  
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.comment-form h3{
  color: #414141ff;
}
.comment-form input,
.comment-form textarea{
  background-color: #fbf9f9ff;
  width: 100%;
  padding: 10px;
  border-radius: 20px;
  border: 1px solid #fbf9f9ff;
}
.comment-form input{
  height: 60px;
}
.comment-form textarea{
  min-height: 100px;
}
.comment-form button{
  background-color: var(--blog-red);
  color: white;
  max-width: 100px;
  padding: 8px 0px;
}
.comment-form button svg{
  width: 70px;
  margin-top: 5px;
}
.comment-form button:hover{
  background-color: #b61b1bff;
}

/*related content*/
.related-content{
  padding: 0px 30px;
  margin-top: 50px;
  margin-bottom: 30px;
}

@media screen and (min-width:712px){
  .blog-story-detail{
    margin-top: 130px;
  }
}
@media screen and (min-width:1280px){
  /* comment */
  .blog-story-detail-comment-wrapper{
    padding: 0px 50px;
  }
  .blog-story-detail-comment-section h2{
     padding: 0px 50px;
  }
  .blog-story-detail-comment-section h2::after{
      left: 50px;
  }
  /* related content */
  .related-content{
    padding: 0px 50px; 
  }
}