@import url(db_pane.css);

:root{
  --goal-gray: #ebebf0ff;
  --goal-yellow: #f9e551ff;
  --goal-green: #50be58ff;
}

.info-details{
  border: 1px solid #eaebefff;
  border-radius: 30px;
  
  display: flex;
  flex-direction: column;
  gap: 50px;
}

/*----------basic info----------*/
.basic-info{
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 30px 20px 0px 20px;
}
.basic-info .tag{
  color: #262e39ff;
  font-size: 20px;
  padding-left: 10px;
  margin-bottom: 10px;
}
.basic-info .value{
  padding: 20px;
  border-radius: 15px;
  color: #656565ff;
  font-size: 16px;
}

/*user credential*/
.user-credentials{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 30px;
}
.user-credentials>div{
  flex: 1;
  max-width: 300px;
}
.user-credentials .value{
  background-color: #fbf9f9ff;
}

/*access dates*/
.access-dates-et-user-agent{
  display: flex;
  align-items: flex-end; 
  flex-wrap: wrap;
  gap: 30px;
  justify-content: space-between;
}
.access-dates-et-user-agent>div{
  flex: 1;
  max-width: 300px;
}
.access-dates-et-user-agent .value{
  border: 2px solid #f7f3f3ff;
  
  display: flex;
  align-items: center;
  gap: 5px;
}
.access-date-time{
  font-size: 13px;
  color: #454545ff;
}

/*user agent*/
.user-agent{
  font-size: 14px !important;
  color: #515239ff !important;
}

/*----------user preferences-----------*/
.user-preferences{
  background-color: #f9f6f6ff;
  padding: 30px 20px;
  border-radius: inherit;
}
.user-preferences h3{
  color: #3c2828ff;
  margin-bottom: 30px;
}
.preferences-wrapper{
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}
.preferences-wrapper>div{
  flex: 1;
  flex-basis: 250px;
  max-width: 300px;
  
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.preferences-wrapper .tag{
  color: #262e39ff;
  font-size: 18px;
}
.preferences-wrapper .value{
  color: #656565ff;
  
  display: flex;
  align-items: center;
  gap: 10px;
}
.true-check{
  background-color: #fff;
  width: 23px;
  height: 23px;
  border-radius: 50%;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  
  display: flex;
  justify-content: center;
  align-items: center;
}
.true-check img,
.true-check svg{
  width: 13px;  
}

/*------------campaign goal------------*/
.campaign-goal-wrapper{
  border-radius: inherit;
  padding: 10px 10px 0px 10px;
}
.campaign-goal{
  display: flex;
  padding: 20px 10px;
  flex-direction: column-reverse;
  background-color: #f1f9f2ff;
  border-radius: inherit;
  align-items: center
}
.campaign-goal-details{
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}
.campaign-goal h2{
  color: #262e39ff;
  font-size: 20px; 
  margin-bottom: -10px;
}

/*campaign goal bar*/
.campaign-goal-status-bar-wrapper{
  display: flex;
  gap: 5px;
  align-items: center
}
.campaign-goal-status-bar{
  flex: 1;
  height: 12px;
  border-radius: 10px;
  background-color: linear-gradient(90deg, 
    var(--goal-gray) 0%, var(--goal-yellow) 10%,
    var(--goal-gray) 25%
  );
}
.campaign-goal-status-percentage{
  color: #92aa95ff;
  font-size: 14px;
}

/*amount-remaining*/
.amount-remaining{
  display: flex;
  gap: 5px;
  align-items: flex-end;
}
.goal-slur-image-wrapper{
  width: 50px;  
}
.goal-slur-image-wrapper svg{
  width: 100%;
}
.amount-remaining h5{
  color: #5b755eff;
  margin: 0px 0px 5px 0px;
}
.amount-remaining span{
  color: #7f8b80ff;
  font-size: 15px;
}

/*campaign goal leaves*/
.campaign-goal-leaves{
  width: 100%; 
  max-width: 150px;
}
.campaign-goal-leaves svg{
  width: 100%;
}


@media screen and (min-width:712px){
  .campaign-goal-wrapper{
    padding-top: 30px;
  }
  .campaign-goal{
    flex-direction: row;
    padding: 30px;
    gap: 20px;
  }
  .campaign-goal-status-bar-wrapper{
    max-width: 600px;
  }
}
@media screen and (min-width:1280px){
  .campaign-goal-details{
    max-width: 700px
  }
  .campaign-goal-wrapper{
    padding: 30px;
  }
}