@import url("../landing_base.css");

/* -----------hero section--------------- */
.hero-section{
    margin-top: 200px; margin-bottom: 40px;
}
.hero-image-wrapper{
   height: 400px;
}
.hero-image-inner-wrapper{
    border: 5px #92CD00 dotted;
    transform: rotate(30deg); transform-origin: 0 0; position: relative;
    width: 900px; height: 200px; right: -70px; top: -35px; border-radius: 100px;
}
.hero-image{
    height: 100%; border-radius: inherit; width: 100%;
    background-image: url("https://res.cloudinary.com/derzcse6f/image/upload/v1727513884/hero_llxkjz.png");
    background-size: 70%; background-repeat: no-repeat;  
    background-position: -100px -100px;
}
.hero-text-wrapper{
    padding: var(--rl-pad); font-family: 'norm';
}
.heading-text{
    font-size: 40px; font-weight: bold; color: #2e4049;
    margin-bottom: 20px; max-width: 712px;
}
.hero-buttons{
    display: flex; justify-content: space-between; align-items: center;
    max-width: 320px; margin: auto;
}


@media screen and (min-width:428px){
    /* -----------hero image--------------- */
    .hero-image{ 
        background-position: 0px -120px;
    }
}
@media screen and (min-width:540px){
    /* -----------hero section--------------- */
    .hero-image-wrapper{
        height: 550px;
    }
    .hero-image{
        background-size: 90%;
    }
    .hero-image-inner-wrapper{
        width: 1000px;
    }
}
@media screen and (min-width:712px){
    /* -----------hero section--------------- */
    .hero-section{
        display: flex; flex: 1; flex-direction: row-reverse; align-items: center;
    }
    .hero-text-wrapper{
        flex: 1000px;
    }
    .hero-image-wrapper{
        min-width: 200px;
    }
    .hero-buttons{
        margin: 0px;
    }
    .hero-section{
        margin-bottom: 0px
    }
}

@media screen and (min-width:1280px){
    /* -----------hero section--------------- */
    .hero-image-wrapper{
        overflow: hidden;
    }
    .service-image-wrapper{
        height: 400px;
    }
    .hero-image-inner-wrapper{
        height: 210px;
    }
    .hero-section{
        margin-top: 130px
    }
}