@import url(../landing_base.css);

/* -----------donation section--------------- */
.donate-section{
    background: var(--gray); padding: var(--rl-pad); padding-top: 30px;
}
.scroll-note{
    text-decoration: underline; text-decoration-color: #00bd8eff; font-family: var(--med);
    text-align: right; margin-bottom: 10px; text-decoration-thickness: 2px;
}
.ds{
    position: relative;
    height: 42px; display: none; margin-bottom: 10px;
}
.ds>div{
    width: 42px; height: 100%; position: absolute;  background: white;
    transition: opacity 1s; 
    cursor: pointer; border-radius: 50%; box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.06);
}
.ds1{
    right: 60px; display: flex; align-items: center; justify-content: center;
}
.ds1>span{
    margin-top: 6px; margin-left: -2px; display: none;
}
.ds2>span{
    margin-left: 2px; display: none;
}
.ds2{
    right: 0px; 
}

.donation-heading{
    min-width: 100%; text-align: center;
}
.donation-slide{
    overflow-x: auto; 
    height: 520px; 
    position: relative;
    scrollbar-width:none; 
    -ms-overflow-style:none;
    margin-left: 70px;
}
.donation-slide::-webkit-scrollbar{
    display:none;
}
.donation-slide>div{
    width: 320px; height: 400px; position: absolute;
}
.campaign-image-wrapper{
    height: 180px;
}
.campaign-image-wrapper img{
    object-fit: cover;
}
.donation-detail{
    background: white; width: 92%; margin: auto; margin-top: -20px;
    padding: 10px; border-radius: 4px;
}
.donation-detail .tag{
    background: #ecfdf3; display: inline-flex; padding: 5px 10px 5px 10px; font-size: 14px;
    color: #027A48; font-family: var(--med); border-radius: inherit;
}
.case{
    color: var(--dark); font-weight: bold; font-size: 25px; padding-top: 10px;
}
.progress-bar{
    padding-top: 20px   
}
.bar{
    width: 100%;
    border-radius: 10px; 
    background: #eaecf0; 
    height: 12px; 
    padding: 2px 2px 1px 2px;
    overflow: hidden;
}
.inner-bar{
    background: #92cd00; 
    height: 90%; 
    border-radius: inherit;
    transition: 0.5s
}
.mark{
    padding-top: 20px; display: flex; justify-content: space-between;
}
.mark>div>span:nth-of-type(1){
    color: #667085; font-family: var(--med);
}
.mark>div>span:nth-of-type(2){
    color: #2e4049; font-weight: bold; font-size: 18px;
}
.donate-sect-button-wrapper{
    padding-top: 30px; display: flex; justify-content: space-around; align-items: center;
}
.donate-button{
    height: 55px; width: 150px;
}
.donate-button span{ 
   font-size: 20px
}
.share-button{
    width: 50px; height: 50px; display: flex; display: none;
}


.donation7{
    left: 362px
}
.donation10{
    left: 724px;
}
.donation8{
    left: 1086px;
}
.donation11{
    left: 1448px;
}
.donation6{
    left: 1810px;
}
.donation5{
    left: 2172px;
}
.donation4{
    left: 2534px;
}
.donation3{
    left: 2896px;
}
.donation2{
    left: 3258px;
}
.donation1{
    left: 3620px;
}

@media screen and (min-width: 1280px){
    .donation-slide{
        margin-left: 70px;
    }
}