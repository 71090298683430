@import url('landing_base.css');

/* -----------top bar--------------- */
.top-bar{
    padding-bottom: 10px; 
    left:-1%; 
    padding-left: 1%;
    position:fixed; 
    top:0; 
    width:101%; 
    background: white; 
    z-index:10;
    box-shadow:1px 1px 10px 0px rgba(0,0,62,0.1);
    padding: var(--rl-pad); 
    padding-top: 10px;
}
.top-bar-title{
    display: flex; 
    gap: 10px;
    align-items: center; 
    color: #293800ff;
    position: relative;
    top: 10%;
    
    font-size: 16px;
    font-weight: 500;
    font-family: var(--segoe-ui-font) !important;
}
.top-bar-title>span{
    display: flex;
}
.top-nav{
    display: flex; 
    justify-content: flex-end;
    padding: 15px 0px 10px 20px;
    flex-wrap: wrap;
}
.top-nav>div{
    color: #475467; 
    font-size: 18px; 
    font-family: var(--codec-font);
    font-weight: bold; 
    line-height: 24px; 
    padding: 0px 10px; 
    /*
    border-left: 2px solid var(--Grey---Dark);
    */
    color: #92CD00; 
    margin-bottom: 10px;
    position: relative
}
.top-nav>div::before{
    position: absolute;
    content: '';
    width: 5px;
    height: 5px;
    background-color: var(--Grey---Dark);
    background-color: var(--bs-gray-500);
    top: 5px;
    right: 3px;
}
.top-nav a{
    color: #92CD00; 
    text-decoration: none;
}
.top-nav a:hover{
    text-decoration: underline;
}
.top-bar-contact-butt{
    width: 120px; 
    height: 50px; 
    background: #92CD00; 
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    gap: 8px; 
    position: absolute;
    top: 20px; 
    right: 10px; 
}
@media screen and (min-width:712px){
    /* -----------top bar--------------- */
    .top-bar{
        display: flex; 
        justify-content: space-between; 
        padding-bottom: 10px;
    }
    .top-nav{
        margin-right: 130px; 
        height: 45px;
        margin-top: 35px;
    }   
    .top-nav>div{
        border-left: none;
    }
    .top-bar-contact-butt{
        top: 40px
    }
}

@media screen and (min-width:912px){
    /* -----------top bar--------------- */
    .top-bar-contact-butt{
        width: 150px;
    }
    .top-bar-contact-butt-text-wrapper{
        font-size: 20px;
    }
    .top-nav{
        margin-right: 160px;
    }
    .top-bar-title{
        padding-left: 20px;
        font-size: 18px;
    }
}