@import url("../landing_base.css");

footer{
    background-color: #f9f6f6ff;
    padding: 30px;
    margin-top: 100px;
    position: relative;
}
    
.footer-sub{
    display: flex;
    flex-direction: column;
    gap: 40px;
    
    max-width: 1000px;
    margin: auto;
}

footer .red-heart{
    position: absolute;
    top: -10px;
    left: 20px;
    width: 30px;
    height: 30px;
}
footer .red-heart svg{
    width: 100%;
    height: 100%;
}


{/*footer title*/}    
footer .title{
    font-size: 22px;
    font-weight: 600;
    font-family: var(--segoe-ui-font);
    color: #4d0013ff;
}
footer .title a{
    color: #4d0013ff;
}

/*footer nav*/
.footer-nav{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

/*footer-sub-nav*/
.footer-sub-nav{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;
    gap: 20px;
}

/*footer-sub-nav-site-links*/
.footer-sub-nav-site-links{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

/*site links*/
.footer-site-links a{
    position: relative;
    color: #d62525ff;
    font-size: 18px;
}
.footer-site-links a::after{
    position: absolute;
    content: '';
    width: 5px;
    height: 5px;
    background-color: #393939ff;
    top: 5px;
    right: -8px;
}

/*socials*/
.socials{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
}

/*address mobile*/
.address-mobile{
    color: #5f5f5fff;
}

/*contact note*/
.contact-note{
    display: flex;
    gap: 10px;
    align-items: center;
    max-width: 500px;
    margin: auto;
}
.contact-note a{
    text-wrap: nowrap
}
.contact-note p{
    text-align: justify;
}

.copyright-info{
    color: #494949ff;
    text-align: center;
}