@import url("../landing_base.css");

/* -----------top bar--------------- */
.hero-section2{
    padding: var(--pad); 
    height: 600px; 
    margin-bottom: 30px;
    position: relative;
}
.hero-section2>img{
    object-fit: cover;    
}
.hero-section2 .hero-text-wrapper{
    position: absolute;
    top: 0;
    left: 0;
    
    width: 100%;
    height: 100%;
    color: white;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.hero-section2 p{
    font-size: 20px; 
    font-family: var(--med);
    text-shadow: 5px 5px 10px rgba(0, 0, 0, 1);
}
.hero-section2 .hero-note,.hero-section2 .learn-more-button span{
    color: white;
}
.hero-section2 .learn-more-button{
    background: rgb(255,255,255,0.3); border: 1px solid white
}

@media screen and (min-width:912px){
    .hero-section2 .hero-text-wrapper{
        padding-left: 50px;
    }
}