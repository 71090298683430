@import url("../landing_base.css");

/* -----------testimonials section--------------- */
.testimonial-section{
    padding: 20px;    
}
.testimonial-heading{
    min-width: 100%; text-align: center;
}
.testimonial-wrapper{
    background: var(--gray); width: 100%; max-width: 1000px; margin: auto; padding: 20px;
    border-radius: 4px; margin-bottom: 50px;
}
.quote-symbol{
   text-align: center; margin-bottom: 60px;
}
.testimony{
    color: var(--dark); font-size: 20px;
}
.testimony-note{
    font-family: var(--med); width: 100%;
    max-width: 600px; margin: auto; margin-bottom: 30px
}
.testimony-author{
    font-size: 20px; font-weight: bold; text-align: center;
    margin-bottom: 30px;
}
.index-indicator{
    height: 10px; display: flex;
    justify-content: center; gap: 5px;
}
.index-indicator>div{
    border-radius: 50%; width: 8px; height: 8px; background: var(--dark);
    /*eaecf0*/
}

.stats{
    display: flex; 
    flex-wrap: wrap; 
    justify-content: space-between;
    gap: 50px;
}
.stats>div{
    flex: 1 250px;
    max-width: 400px;
}
.numbers{
    font-size: 40px; font-weight: bold; color: #2c6700;
}
.numbers+span{
    color: #101828; font-size: 20px; font-family: var(--med);
}
.numbers+span+p{
    color: #475467; 
    font-size: 18px; 
    font-family: var(--med);
    text-align: justify;
}

@media screen and (min-width:912px){
    .stats{
        padding: 0px 50px;
    }
}