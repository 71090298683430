@import url("../landing_base.css");

/* -----------founders--------------- */
.founders{
    padding: var(--rl-pad); 
    padding-top: 30px; 
    margin-bottom: 30px;
    background-color: #faf9f4ff;
    padding-bottom: 50px;
}
.founders-gallery{
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    max-width: 1200px;
    margin: auto;
}
 
.founders-entry{
    max-width: 400px;
    flex: 1 250px; 
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    
    margin-bottom: 20px;
}
.founders-entry img{
    object-fit: cover
}
.founders-entry .slide-name{
    background-color: #fff;
    width: 100%;
}
.slide-photo{
    height: 250px; 
    width: 100%; 
    border-top-right-radius: inherit;
    border-top-left-radius: inherit;
}
.slide-name{
    font-size: 19px; 
    font-weight: bold; 
    padding: 20px 10px;
    color: var(--bs-gray-800);
    
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
    background: linear-gradient(180deg, #fff, #fff 50%, #faf9f4ff 50%);
}



@media screen and (min-width:912px){
}