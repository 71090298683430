@import url("preview_cards.css");

/*preview insights*/
.preview-impact-cards{
    margin-bottom: 20px;
    
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 400px));
    gap: 20px;
}
.preview-impact-card{
    width: 100%;
    max-width: 400px;
}
.preview-impact-card-thumbnail{
    height: 280px;
    border-radius: 15px;
}
.preview-impact-card-thumbnail img{
    object-fit: cover;
}
.preview-impact-card p{
    color: #2e333aff;
    font-size: 15px;
    font-family: var(--arial);
}


@media screen and (min-width:912px){    
    /*impact card*/
    .preview-impact-card{
        border: 1px solid #eaebefff;
        padding: 20px;
        border-radius: 20px;
    }
}
@media screen and (min-width: 1280px) {
    .preview-impact-cards {
      grid-template-columns: repeat(auto-fit, minmax(350px, 400px));
    }
  }