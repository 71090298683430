@import url("fundraiser_base.css");

.donate-form p{
    font-size: 16px; 
    color: #202020ff;
}

.amount-wrapper{
    margin-bottom: 0px;
}
.input-amount-wrapper{
    width: 100%;  
    border-radius: 20px; 
    outline: none; 
    display: flex; 
    padding: 0px 10px 0px 10px; 
    align-items: center;
    border: 1px solid #ccccccff;
}
.input-amount-wrapper input{
    width: 100%; 
    height: 90%; 
    padding: 10px; 
    font-size: 20px; 
    outline: none;
    border: 1px solid rgba(0,0,0,0) !important; 
}
.ty>span{
    display: block; 
    text-align: center; 
    font-weight: bold; 
    color: var(--bs-gray-800)
}
.ty>span:nth-of-type(1){
    font-size: 30px;
}
.ty>span:nth-of-type(2){
    font-size: 15px;
}


/*----------radio buttons----------*/
.custom-radio{
  display: none; /* Hide the default checkbox */
}
.custom-radio-option{
  margin-bottom: 20px;
}
.custom-radio + label {
    position: relative;
    padding-left: 35px; /* Adjust the padding based on your design */
    cursor: pointer; 
    padding-top: 4px;
   color: var(--bs-gray-700);
}

.custom-radio + label:before {
  content: '';
  position: absolute;
  top: 2px;
  left: 0;
  width: 20px; /* Adjust the width and height based on your design */
  height: 20px;
  border: 2px solid #e6ecf5; /* Green border color */
  border-radius: 50%;
  background-color: #fff; /* White background color */
  display: flex; text-align: center; 
  justify-content: center;
  cursor: pointer;
}

.custom-radio:checked + label:before {
  content: '✔'; /* Unicode check mark symbol */
  font-size: 14px; /* Adjust the font size based on your design */
  line-height: 20px; /* Center the check mark vertically */
  text-align: center;
  color: #1ba200ff; /* Green check mark color */
  border: 2px solid;
}

/*required indicator*/
.required-indicator{
    display: flex; align-items: flex-end; gap: 5px;
    margin-bottom: 10px
}
.required-indicator>span:nth-of-type(1){
    border: 1px solid; 
    width: 20px; 
    height: 20px;
    border-radius: 50%; 
    display: flex; 
    padding: 1px 0px 0px 5px;
}
.required-indicator>span:nth-of-type(2){
    color: #da2626ff;
    color: var(--pending);
}
textarea{
    border: 1px solid #ccccccff;
}



.donate-wrapper,
.reason,
.tip{
    border-top: 1px solid rgba(32,32,32,0.1); 
    color: #202020ff;
    padding-top: 15px; 
    padding-bottom: 15px;
}


