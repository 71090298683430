@import url("../landing_base.css");

/* ----------- section4 --------------- */
.lil-sec-cta-wrapper{
    padding: 20px;
}
.lil-sec-cta{
    background: #f5f5f5ff; 
    border-radius: 25px; 
    padding: 20px;
    margin: auto; 
    display: flex; 
    flex-wrap: wrap; 
    margin-bottom: 50px;
    max-width: 1200px;
}
.lil-sec-cta img{
    object-fit: cover
}
.lil-sec-cta-flex1{
    margin-bottom: 30px;
    display: flex; flex-direction: column; align-items: center;
}
.lil-sec-cta-flex1>.heading-text{
    color: #37a500ff; font-size: 40px; padding-top: 50px;
}
.lil-sec-cta-flex2{
    flex: 100%;
}
.lil-sec-cta-flex2>div{
    height: 400px; border-radius: 20px;
}

@media screen and (min-width:1280px){
    /* -----------section 4--------------- */
    .lil-sec-cta{
        flex-wrap: no-wrap;  
        width: 100%; 
        margin-top: 50px;
    }
    .lil-sec-cta>div{
        flex: 50%;
    }
    .lil-sec-cta-flex2{
        display: flex; align-items: center;
    }
    .lil-sec-cta-flex2>div{
        height:300px; width: 100%;
    }
}